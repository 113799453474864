import React from 'react'

import { Modal, Button, CloseButton } from './style'

import Success from '../../assets/image/success.png'
import Error from '../../assets/image/error.png'
import Close from '@interco/icons/build-v4/bidis/v2/navigation/close'

const menssagens = {
  error: {
    title: 'Falha no envio do formulário',
    paragraph: 'Ocorreu um erro de comunicação no processo de envio. Tente novamente. ',
  },
  success: {
    title: 'Formulário enviado com sucesso',
    paragraph: null,
  },
}

type ModalMensssageProps = {
  type?: string;
  setOpen: Function;
}

const ModalMenssage = ({ setOpen, type }: ModalMensssageProps) => {
  const handleClick = () => {
    setOpen(false)
    document.location.reload()
  }

  return (
    <Modal>
      <div className='relative'>
        <CloseButton onClick={() => handleClick()}>
          <Close height={24} width={24} color='#FF7A00' />
        </CloseButton>
        {
        type === 'success' ? <img src={Success} /> : <img src={Error} />
      }
        <h3 className='fs-20 lh-25 text-grayscale--500 fw-600 text-center mt-3'>{menssagens[type as 'success' | 'error'].title}</h3>
        {menssagens[type as 'success' | 'error'].paragraph && <p className='fs-14 lh-17 text-grayscale--500 text-center'>{menssagens[type as 'success' | 'error'].paragraph}</p>}
        <Button onClick={() => handleClick()}>Entendi</Button>
      </div>
    </Modal>
  )
}

export default ModalMenssage
