import React, { useState, ChangeEvent } from 'react'
import axios from 'axios'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useDomReady from 'src/hooks/window/useDomReady'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLs from 'src/config/api/Urls'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import { sendCDPFormData } from 'src/shared/helpers'
import AcceptTerms from 'src/components/AcceptTerms'
import ModalMenssage from '../../_components/_modal/_index'
import { Modal } from 'src/components/Modal'

import { Section, Card, Button } from './style'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IFormValues {
  nome: string;
  email: string;
  CpfCnpj: string;
  conteudo01: string;
  celular: string;
}

function formatNumber (telefone: string): string {
  const cleanNumber = telefone.replace(/[()\s-]/g, '')
  return '55' + cleanNumber
}

const Hero = () => {
  const [ loading, setLoading ] = useState(false)
  const { register, errors, handleSubmit, setValue }: UseFormMethods<IFormValues> = useForm<IFormValues>()
  const [ accept, setAccept ] = useState(false)
  const [ openModal, setOpenModal ] = useState(false)
  const [ type, setType ] = useState('success')
  const [ sendDatalayerEvent ] = useDataLayer()

  const dataLayer: IDataLayerParams = {
    section: 'dobra_01',
    element_action: 'click button',
    section_name: 'Trago seu dinheiro de volta!',
    element_name: 'Política de Privacidade',
  }

  const domReady = useDomReady()
  const ModalMenssagem = domReady && (
    <Modal isModalOpen={openModal} setIsModalOpen={setOpenModal} locationToRender={document.body}>
      <ModalMenssage type={type} setOpen={setOpenModal} />
    </Modal>
  )

  const formSubmit = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      CpfCnpj: data.CpfCnpj.replace(/\D/g, ''),
      aceite: accept,
      campanha: 'Antecipacao imposto de renda',
      conteudo01: formatNumber(data.conteudo01),
    }

    try {
      await axios.post(`${URLs.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      setType('success')
      setOpenModal(true)
      sendCDPFormData({ formName: 'Antecipacao imposto de renda', cpf: data.CpfCnpj, email: data.email })
      sendDatalayerEvent({
        section: 'dobra_01',
        element_action: 'submit',
        section_name: 'Trago seu dinheiro de volta!',
        element_name: 'Cadastrar',
        step: 'success',
       })
    } catch (err) {
      setLoading(false)
      setType('error')
      setOpenModal(true)
    }
  }

  return (
    <Section className='py-5 d-flex align-items-center justify-content-between'>
      {
        ModalMenssagem
      }
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-md-6 col-xl-5'>
            <h1 className='fs-32 lh-40 fs-lg-40 lh-lg-50 fs-xl-40 lh-xl-60 text-white fw-600'>Trago seu dinheiro de volta!</h1>
            <p className='fs-18 lh-22 text-white'>Chega de esperar meses para receber a sua restituição de Imposto de Renda!</p>
            <p className='fs-18 lh-22  text-white'>Esse dinheiro é seu, e você merece ter ele na mão a hora que quiser.</p>
          </div>
          <div className='col-12 col-md-6'>
            <Card>
              <h2 className='fs-14 lh-17 fs-md-16 lh-md-20 fs-xl-20 lh-xl-25 text-grayscale--500'>Cadastre-se e  garanta sua antecipação!</h2>
              <p className='fs-14 lh-17 text-grayscale--500 mb-4'>Preencha o formulário e saiba como receber a sua restituição do Imposto de Renda antecipadamente</p>
              <form onSubmit={handleSubmit(formSubmit)}>
                <div className={`${errors.nome && 'error'}`}>
                  <label className='fs-14 lh-17 text-grayscale--500 d-block mt-3'>
                    Nome
                  </label>
                  <input
                    type='text'
                    placeholder='informe seu nome completo'
                    name='nome'
                    ref={register({
                      required: 'Por favor, digite seu nome completo',
                      validate: {
                        isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                      },
                    })}
                  />
                  {errors.nome && <p className='fs-12  mb-0 text-right'>{errors.nome.message}</p>}
                </div>
                <div className={`${errors.nome && 'error'}`}>
                  <label className='fs-14 lh-17 text-grayscale--500 d-block mt-3'>
                    CPF
                  </label>
                  <input
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCPF(event.currentTarget.value))}
                    type='text'
                    placeholder='informe o número'
                    name='CpfCnpj'
                    ref={register({
                  required: 'Digite um CPF válido',
                  validate: {
                    isCpf: (value: string) => Validations.cpf(value) || 'CPF  Inválido',
                  },
                })}
                  />
                  {errors.CpfCnpj && <p className='fs-12  mb-0 text-right'>{errors.CpfCnpj.message}</p>}
                </div>
                <div className={`${errors.nome && 'error'}`}>
                  <label className='fs-14 lh-17 text-grayscale--500 d-block mt-3'>
                    Email
                  </label>
                  <input
                    type='text'
                    placeholder='informe o seu e-mail'
                    name='email'
                    ref={register({
                  required: 'Digite um e-mail válido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'E-mail inválido',
                  },
                })}
                  />
                  {errors.email && <p className='fs-12  mb-0 text-right'>{errors.email.message}</p>}
                </div>
                <div className={`${errors.nome && 'error'}`}>
                  <label className='fs-14 lh-17 text-grayscale--500 d-block mt-3'>
                    Celular com DDD
                  </label>
                  <input
                    type='text'
                    name='conteudo01'
                    placeholder='(xx) xxxx-xxxx'
                    ref={register({
                      required: 'Digite um telefone válido',
                      pattern: {
                        value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                        message: 'Telefone inválido',
                      },
                    })}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('conteudo01', Masks.MaskPHONE(event.currentTarget.value))}
                  />
                  {errors.conteudo01 && <p className='fs-12 text-red--base mb-0 text-right'>{errors.conteudo01.message}</p>}
                </div>
                <div className='mt-4'>
                  <AcceptTerms accept={accept} setAccept={setAccept} dataLayer={dataLayer} />
                  <Button
                    onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_01',
                      element_action: 'click button',
                      section_name: 'Trago seu dinheiro de volta!',
                      element_name: 'Cadastrar',
                     })
                    }}
                    type='submit'
                    disabled={!accept || loading}
                  >
                    Cadastrar
                  </Button>
                </div>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
