import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'
import { grayscale, orange, white, red, gray } from 'src/styles/colors'
import BgSm from '../../assets/image/prepaymentOfIncomeTax-sm.png'
import BgMd from '../../assets/image/prepaymentOfIncomeTax-md.png'
import BgLg from '../../assets/image/prepaymentOfIncomeTax-lg.png'
import BgXl from '../../assets/image/prepaymentOfIncomeTax-xl.png'

export const Section = styled.section`
  background-image: url(${BgSm}) ;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${breakpoints.md}){
    background-image: url(${BgMd}) ;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 749px;
  }
  @media (min-width: ${breakpoints.lg}){
    background-image: url(${BgLg}) ;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 741px;
  }
  @media (min-width: ${breakpoints.xl}){
    background-image: url(${BgXl}) ;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 648px;
  }

  .error {
    label, p{
      color: ${red[500]};
    }
    input, details{
      background: ${red.error} ;
    }

    }


`
export const Card = styled.div`
  width: 100%;
  background: ${white};
  border-radius: 8px ;
  padding: 24px ;


  input {
    height: 48px;
    width: 100%;
    background: ${gray[400]};
    border: none ;
    border-radius: 8px;
    padding: 0 16px;

    &:focus{
      outline:none ;
    }

    &::placeholder{
      font-size: 14px;
      line-height: 18px;
      color: ${grayscale[300]} ;
    }



  }


`
export const Button = styled.button`
   background: ${orange.extra};
   height: 48px;
   width: 100%;
   border-radius: 8px;
   color: ${white};
   font-size: 14px;
   line-height: 17px;
   font-weight: 600;
   border: none;
   margin-top: 16px ;

   &:focus {
    outline: none;
   }

   &:disabled{
    background: ${grayscale[200]} ;
    color: ${grayscale[300]};

   }

`
