import React from 'react'

import { Section } from './style'

import Img from 'gatsby-image'
import ComputerCall from '@interco/icons/build-v4/orangeds/SM/computer-call'
import InterResearch from '@interco/icons/build-v4/orangeds/MD/inter-research'
import MoneyUp from '@interco/icons/build-v4/orangeds/XL/money-up'
import CardProtection from '@interco/icons/build-v4/orangeds/LG/card-protection'

import pageQuery from '../../pageQuery'

const NoMoreWaiting = () => {
  const data = pageQuery()
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-center'>
          <div className='col-12 col-md-6'>
            <Img fluid={data.noMoreWaiting.fluid} className='d-md-none d-block img' />
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500 fw-600 mb-4'>
              Por que antecipar minha restituição de Imposto de Renda?
            </h2>
            <div className='d-flex'>
              <ComputerCall height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 fw-700 ml-3'>Dinheiro rápido na conta para usar como quiser</p>
            </div>
            <div className='d-flex'>
              <InterResearch height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 fw-700 ml-3'>Recebimento do valor em parcela única</p>
            </div>
            <div className='d-flex'>
              <MoneyUp height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 fw-700 ml-3'>Contratação 100% digital</p>
            </div>
            <div className='d-flex'>
              <CardProtection height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 fw-700 ml-3'>Taxas fixas e sem surpresa</p>
            </div>
            <div className='d-flex'>
              <ComputerCall height={24} width={24} color='#FF7A00' />
              <p className='fs-16 lh-20 fs-md-18 lh-md-22 text-grayscale--500 fw-700 ml-3'>Zero burocracias</p>
            </div>
          </div>
          <div className='col-12 col-md-6 d-md-block d-none'>
            <Img fluid={data.noMoreWaiting.fluid} />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default NoMoreWaiting
