import React from 'react'

import { Wrapper } from './style'

import pageContext from './pageContext.json'

import Layout from 'src/layouts/BaseLayout'

// Sections
import Hero from './_sections/_hero/_index'
import NoMoreWaiting from './_sections/_no-more-waiting/_index'
import FAQ from './_sections/_faq/_index'

const PrepaymentOfIncomeTax = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <NoMoreWaiting />
        <FAQ />
      </Layout>
    </Wrapper>
  )
}

export default PrepaymentOfIncomeTax
